/* eslint-disable import/no-unresolved */
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
// components
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import LandingNavbar from 'src/layouts/landing/LandingNavbar';
import Page from '../components/Page';
import AppOrderTimeline from '../components/_dashboard/app/AppOrderTimeline';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const MainStyle = styled('div')(({ theme }) => ({
  //   zIndex: -1,
  //   backgroundImage: 'url(/static/landing.jpeg)',
  position: 'relative',
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20)
  }
}));

const Landing = () => {
  const navigate = useNavigate();

  // Local state and vars
  const desktopImage = '/static/landing.jpeg';
  const mobileImage = '/static/landing-mobile.jpeg';
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth >= 650 ? desktopImage : mobileImage;
  const aboutRef = useRef();
  const toolsRef = useRef();

  // Function to scroll to specified ref
  const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

  // Hooks to handle window resize events (for background image)
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Page title="Opoku Homes">
      {/* Background image */}
      <img
        src={imageUrl}
        alt="background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -9999,
          backgroundSize: 'cover',
          filter: 'brightness(50%)',
          overflow: 'hidden',
          height: '100vh',
          width: '100%',
          // transform: 'translate(-50%, -50%)',
          // objectFit: 'cover'
          // height: '100vh',
          // width: '100%'
          // height: '100vh'
          // maxHeight: 'none',
          maxWidth: 'none'
        }}
      />

      {/* Top navbar */}
      <LandingNavbar scrollToRef={scrollToRef} aboutRef={aboutRef} toolsRef={toolsRef} />

      {/* Page contents */}
      <MainStyle>
        <Container maxWidth="xl">
          {/* Contact info form */}
          <Stack
            direction="column"
            alignItems="left"
            justifyContent="space-between"
            mt="15%"
            mb="25%"
          >
            <Typography variant="h1" gutterBottom color="white">
              get ready
              <br />
              we're finishing!
            </Typography>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                sx={{ backgroundColor: 'white', borderRadius: 1 }}
                type="email"
                variant="filled"
                label="Example: abc@mail.com"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <Icon icon="bi:arrow-right" width="20" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Stack>
          {/* <br /> <br /> <br /> */}
          {/* Company info tile callouts */}
          <Grid container spacing={3} sx={{ mt: windowWidth >= 650 ? '45vh' : '60vh' }}>
            <Grid item xs={12} md={4} lg={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Stack direction="row" spacing={3} sx={{ mb: 1.5 }}>
                    <Icon icon="ant-design:safety-outlined" style={{ fontSize: 28 }} />
                    <Typography sx={{ fontSize: 14 }} variant="h5" gutterBottom>
                      modern living
                    </Typography>
                  </Stack>

                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    2610 Fern offers a selection of one-bedroom apartments with modern finishes,
                    natural lighting, and open-concept interiors
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Stack direction="row" spacing={3} sx={{ mb: 1.5 }}>
                    <Icon icon="bi:peace" style={{ fontSize: 25, marginTop: '2px' }} />
                    <Typography sx={{ fontSize: 14 }} variant="h5" gutterBottom>
                      peaceful location
                    </Typography>
                  </Stack>

                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Discover a tranquil neighborhood that immediately feels like home, with easy
                    access to Edinburg's main streets and the UTRGV
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Stack direction="row" spacing={3} sx={{ mb: 1.5 }}>
                    <Icon icon="clarity:savings-line" style={{ fontSize: 30 }} />
                    <Typography sx={{ fontSize: 14 }} variant="h5" gutterBottom>
                      safe and secure
                    </Typography>
                  </Stack>

                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    We commit to your safety with exterior CCTV and ensuring that you can easily get
                    in touch with us in case of concerns
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            </Grid>
          </Grid>
          <br /> <br /> <br />
          {/* About the company */}
          <Typography
            ref={aboutRef}
            variant="h3"
            gutterBottom
            color="black"
            sx={{ scrollMarginTop: '5rem' }}
          >
            about the property
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5} lg={5}>
              <Typography variant="body" gutterBottom color="black">
                The apartments at 2610 Fern Ave., Edinburg, TX features eight units, each with
                modern architecture and premium finishes.
                <br /> <br />
                2610 Fern offers cozy, light, and airy interiors so that you can relax after a long
                day. It is located conviently close to grocery stores, shopping centers, and the
                University of Texas at Rio Grande Valley.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <AppOrderTimeline />
            </Grid>
          </Grid>
          <br /> <br /> <br />
          {/* Out tools */}
          <Typography
            ref={toolsRef}
            variant="h3"
            gutterBottom
            color="black"
            sx={{ scrollMarginTop: '5rem' }}
          >
            tools for investors
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body" gutterBottom color="black">
                Our Rental Dashboard tool helps you underwrite your rental property by estimating
                key financial measures, including cashflow, net operating income, and cap rate.
                <br /> <br />
                The tool will also allow you to create printable PDFs to share with your business
                partners and lenders.
                <br /> <br />
                Please note that we greatly value privacy—to that end, we do not store user data,
                nor do we store the financial projections that we provide.
                <br /> <br />
                Try out the beta version of the Rental Dashboard! We welcome any feedback that you
                may have.
              </Typography>
              <br /> <br />
              <Button
                variant="contained"
                style={{ textTransform: 'none' }}
                onClick={() => navigate('/dashboard/input')}
              >
                try it free
              </Button>
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
              <img src="/static/preview.png" alt="preview" style={{ width: '120%' }} />
            </Grid>
          </Grid>
          {/* <Typography variant="subtitle2">©2022 Opoku Homes LLC. All rights reserved.</Typography> */}
        </Container>
      </MainStyle>
      <footer style={{ textAlign: 'center' }}>
        <small style={{ color: 'black' }}>
          Made with <Icon icon="bi:heart-fill" color="red" /> by{' '}
          <a href="https://github.com/joseio">joseio</a>
        </small>
      </footer>
      <footer style={{ textAlign: 'center', background: 'black' }}>
        <small style={{ color: 'white' }}>© 2023 Opoku Homes LLC. All rights reserved.</small>
      </footer>
    </Page>
  );
};
export default Landing;
