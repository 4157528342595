export const mortgage = (ir, loanAmt, term) => {
  // Calculate monthly mortgage payment
  const monthlyGrowth = 1 + ir / 12;
  const monthlyMortgage = (loanAmt * ir) / (12 * (1 - (1 / monthlyGrowth) ** term));
  // Get P&I payments over laon term
  const paymentSchedule = monthlyPaymentSchedule(ir, loanAmt, monthlyMortgage);
  return {
    monthlyMortgage,
    paymentSchedule
  };
};

// Function to Calculate monthly principal and interest payment schedule
const monthlyPaymentSchedule = (ir, loanAmt, monthlyMortgage) => {
  const ret = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const interest = (loanAmt * ir) / 12;
    if (monthlyMortgage >= loanAmt + interest) {
      ret.push({ principal: loanAmt, interest });
      break;
    }
    const principal = monthlyMortgage - interest;
    ret.push({ principal, interest });
    loanAmt -= principal;
  }
  return ret;
};
