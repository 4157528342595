// material
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/oh-logo.png"
      sx={{ marginLeft: '-12.5%', maxHeight: '130%', maxWidth: '130%', ...sx }}
    />
  );
}
