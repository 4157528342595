/* eslint-disable import/no-unresolved */
// material
import { Grid, Paper, Typography } from '@mui/material';

// Function to format dollar amounts with commas
const format = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Stateless functional component
const PropertyInfo = ({ state }) => (
  <Grid
    container
    justify="space-between"
    spacing={5}
    // className="viewOnlyOnPrint"
    style={{ paddingTop: '4rem', paddingLeft: '1.5rem', paddingBottom: '2.5rem' }}
  >
    {/* Property information section */}
    <Grid item xs={6}>
      <Paper elevation={0} variant="outlined" style={{ padding: '1.5rem' }}>
        <Typography inline variant="h6">
          Property information
        </Typography>
        {/* Cash needed to acquire */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            {state.input.construction
              ? 'Cash needed to acquire (incl. land collateral):'
              : 'Cash needed to acquire:'}
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            $
            {format(
              Math.round(state.input.closing + (state.input.percDown / 100) * state.input.price)
            )}
          </Typography>
        </Grid>
        {/* Purchase price */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            {state.input.construction ? 'Construction cost:' : 'Purchase price:'}
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            $
            {format(
              Math.round(
                state.input.construction
                  ? state.input.price - state.input.collateral
                  : state.input.price
              )
            )}
          </Typography>
        </Grid>
        {/* Closing costs */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Closing costs:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.input.closing)}
          </Typography>
        </Grid>
        {/* Estimated construction/repair costs (conditionally rendered) */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            {state.input.construction ? 'Land collateral:' : 'Estimated repair costs:'}
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.input.construction ? state.input.collateral : state.input.repairs)}
          </Typography>
        </Grid>
        {/* Estimated ARV */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Estimated ARV:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.input.arv)}
          </Typography>
        </Grid>
        {/* Total project cost */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Total project cost:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.input.price + state.input.closing + state.input.repairs))}
            {/* TODO: Include note that mentions that bank inclues collateral in total project cost */}
          </Typography>
        </Grid>
      </Paper>
    </Grid>

    {/* Loan information section */}
    <Grid item xs={6}>
      <Paper elevation={0} variant="outlined" style={{ padding: '1.5rem' }}>
        <Typography inline variant="h6">
          Loan information
        </Typography>
        {/* Down payment */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Total down payment:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round((state.input.price * state.input.percDown) / 100))}
          </Typography>
        </Grid>
        {/* Loan amount */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Loan amount:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.input.price * (1 - state.input.percDown / 100)))}
          </Typography>
        </Grid>
        {/* LTV */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            LTV:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {format(100 - state.input.percDown)}%
          </Typography>
        </Grid>
        {/* Loan term */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Loan term:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {state.input.term} years
          </Typography>
        </Grid>
        {/* Loan interest rate */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Loan interest rate:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {state.input.ir}%
          </Typography>
        </Grid>
        {/* Monthly P & I */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Monthly Mortgage (P&I):
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.monthlyBreakdown.Mortgage))}
          </Typography>
        </Grid>
        {/* <br /> */}
      </Paper>
    </Grid>
  </Grid>
);
export default PropertyInfo;
