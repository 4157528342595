import * as Yup from 'yup';

export const InputSchema = Yup.object().shape({
  address: Yup.string().required('Property address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State required'),
  income: Yup.number().min(0).required('Income is required'),
  increase: Yup.number().min(0).required('Yearly increase is required'),
  price: Yup.number().min(0).required('Purchase price is required'),
  closing: Yup.number().min(0).required('Closing costs are required'),
  collateral: Yup.number().min(0).required('Value of land collateral is required'),
  repairs: Yup.number().min(0).required('Repair costs are required'),
  arv: Yup.number().min(0).required('After repair value is required'),
  down: Yup.number().min(0).required('Down payment is required'),
  term: Yup.number().min(0).required('Loan term is required'),
  ir: Yup.number().min(0).required('Interest rate is required'),
  taxes: Yup.number().min(0).required('Taxes are required'),
  insurance: Yup.number().min(0).required('Insurance is required'),
  maintenance: Yup.number().min(0).required('Maintenance is required'),
  vacancy: Yup.number().min(0).required('Vacancy is required'),
  capex: Yup.number().min(0).required('Capital expenditures is required'),
  mgmt: Yup.number().min(0).required('Management is required'),
  elec: Yup.number().min(0).required('Electricity is required'),
  water: Yup.number().min(0).required('Water is required')
});
