/* eslint-disable import/no-unresolved */
// material
import { Grid, Paper, Typography } from '@mui/material';

// Function to format dollar amounts with commas
const format = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Stateless functional component
const FinancialMeasures = ({ state }) => (
  <Grid
    container
    justify="space-between"
    spacing={5}
    // className="viewOnlyOnPrint"
    style={{ paddingLeft: '1.5rem', paddingBottom: '3rem' }}
  >
    {/* Property information section */}
    <Grid item xs={6}>
      <Paper elevation={0} variant="outlined" style={{ padding: '1.5rem' }}>
        <Typography inline variant="h6">
          Key financial measures
        </Typography>
        {/* Cap rate */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Cap rate:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {state.capRate.toFixed(2)}%
          </Typography>
        </Grid>
        {/* ARV based on cap rate */}
        {/* <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            ARV based on cap rate:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.projectedARV))}
          </Typography>
        </Grid> */}
        {/* NOI */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            NOI:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.noi))}
          </Typography>
        </Grid>
        {/* GOI */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            GOI:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.goi)}
          </Typography>
        </Grid>
        {/* Gross rent multiplier */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Gross rent multiplier:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {format((state.input.price / (state.input.income * 12)).toFixed(2))}
          </Typography>
        </Grid>
        {/* 50% rule */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Monthly cashflow needed to satisfy 50% rule:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.fiftyPerc))}
          </Typography>
        </Grid>
        {/* 2% rule */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Monthly rent needed to satisfy 2% rule:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.input.price * 0.02))}
          </Typography>
        </Grid>
        {/* Debt coverage ratio */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Debt coverage ratio:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {format((state.noi / (state.monthlyBreakdown.Mortgage * 12)).toFixed(2))}
          </Typography>
        </Grid>
        {/* Operating expense ratio */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Operating expense ratio:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            {state.oer.toFixed(2)} (ignores depreciation)
          </Typography>
        </Grid>
        <br />
        <br />
      </Paper>
    </Grid>

    {/* Property information section */}
    <Grid item xs={6}>
      <Paper
        // className="viewOnlyOnPrint"
        elevation={0}
        variant="outlined"
        style={{ padding: '1.5rem' }}
      >
        <Typography inline variant="h6">
          Monthly expenses
        </Typography>
        {/* Mortgage */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Mortgage:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.monthlyBreakdown.Mortgage))}
          </Typography>
        </Grid>
        {/* Property taxes */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Property taxes:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.input.taxes / 12))}
          </Typography>
        </Grid>
        {/* Insurance */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Insurance:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.input.insurance)}
          </Typography>
        </Grid>
        {/* Electricity */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Electricity:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.input.elec)}
          </Typography>
        </Grid>
        {/* Water */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Water:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(state.input.water)}
          </Typography>
        </Grid>
        {/* Vacancy */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Vacancy:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format((state.input.income * state.input.vacancy) / 100)}
          </Typography>
        </Grid>
        {/* Capital expenditures */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            CapEx:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format((state.input.capex / 100) * state.input.income)}
          </Typography>
        </Grid>
        {/* Maintenance */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Maintenance:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format((state.input.maintenance / 100) * state.input.income)}
          </Typography>
        </Grid>
        {/* Management */}
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Management:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format((state.input.income * state.input.mgmt) / 100)}
          </Typography>
        </Grid>
        <Grid container justify="space-between">
          <Typography inline variant="body1" align="left" paddingRight="10px">
            Total:
          </Typography>
          <Typography inline variant="body1" align="right" fontStyle="italic">
            ${format(Math.round(state.monthlyExpenses))}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);
export default FinancialMeasures;
