/* eslint-disable import/no-unresolved */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { alpha, useTheme } from '@material-ui/core/styles';
import { MHidden } from 'src/components/@material-extend';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

export default function LandingNavbar(props) {
  // Local state
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const APPBAR_MOBILE = 64;
  const APPBAR_DESKTOP = 75;
  // Trigger to detect when page scrolled
  const trigger = useScrollTrigger({
    target: props.window ? window() : undefined,
    threshold: 0
  });

  //  Map menu item to icon
  const iconMap = {
    about: <Icon icon="akar-icons:info" />,
    tools: <Icon icon="fa-solid:tools" />
  };

  // Define toolbar style
  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5)
    }
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: trigger
            ? alpha(theme.palette.text.primary, 1)
            : alpha(theme.palette.text.primary, 0),
          transition: 'background-color 0.5s'
        }}
        elevation={trigger ? 2 : 0}
      >
        <Toolbar>
          <IconButton color="inherit" edge="start" sx={{ pl: 4 }}>
            <img
              src="/favicon/transparent/oh-logo-48x48.png"
              alt="logo"
              style={{ width: 40, height: 40 }}
            />
            <Typography variant="h4" sx={{ pl: 1.5, fontWeight: trigger ? 'light' : 'bold' }}>
              opoku homes
            </Typography>
          </IconButton>

          {/* Spacer */}
          <div style={{ flexGrow: 1 }} />

          {/* Hide toolbar buttons when window size decreased */}
          <MHidden width="lgDown">
            <Button sx={{ textTransform: 'none', mr: 2 }} variant="contained">
              <Typography variant="h6" sx={{ fontWeight: trigger ? 'light' : 'bold' }}>
                apply now
              </Typography>
            </Button>
            <Button
              sx={{ textTransform: 'none', mr: 2 }}
              color="inherit"
              onClick={() => props.scrollToRef(props.aboutRef)}
            >
              <Typography variant="h6" sx={{ fontWeight: trigger ? 'light' : 'bold' }}>
                about
              </Typography>
            </Button>
            <Button
              sx={{ textTransform: 'none' }}
              color="inherit"
              onClick={() => props.scrollToRef(props.toolsRef)}
            >
              <Typography variant="h6" sx={{ fontWeight: trigger ? 'light' : 'bold' }}>
                tools
              </Typography>
            </Button>
          </MHidden>

          {/* Show overflow menu when window size decreased */}
          <ToolbarStyle>
            <MHidden width="lgUp">
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => setMenuOpen(true)}
              >
                <Icon icon={menu2Fill} />
              </IconButton>
            </MHidden>
          </ToolbarStyle>

          {/* Show drawer when overflow menu clicked */}
          <Drawer
            anchor="right"
            PaperProps={{
              sx: {
                backgroundColor: 'black'
              }
            }}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <Box
              sx={{ width: 225 }}
              role="presentation"
              // onClick={toggleDrawer(anchor, false)}
              // onKeyDown={toggleDrawer(anchor, false)}
            >
              <List>
                <ListItem sx={{ color: 'white' }} button>
                  <ListItemIcon>
                    <Icon icon="fluent:form-new-20-regular" />
                  </ListItemIcon>
                  <ListItemText primary="apply now" />
                </ListItem>
                <ListItem
                  sx={{ color: 'white' }}
                  button
                  onClick={() => props.scrollToRef(props.aboutRef)}
                >
                  <ListItemIcon>
                    <Icon icon="akar-icons:info" />
                  </ListItemIcon>
                  <ListItemText primary="about" />
                </ListItem>
                <ListItem
                  sx={{ color: 'white' }}
                  button
                  onClick={() => props.scrollToRef(props.toolsRef)}
                >
                  <ListItemIcon>
                    <Icon icon="fa-solid:tools" />
                  </ListItemIcon>
                  <ListItemText primary="tools" />
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
