/* eslint-disable import/no-unresolved */
// material
import { Icon } from '@iconify/react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import { createRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import CashflowPaymentsTable from 'src/printable/CashflowPaymentsTable';
import FinancialMeasures from 'src/printable/FinancialMeasures';
import PropertyInfo from 'src/printable/PropertyInfo';
import account from 'src/_mocks_/account';
// components
import Page from '../components/Page';
import {
  CashflowPaymentsTile,
  CashFlowTile,
  ExpenseBreakdownTile,
  ExpensesTile,
  IncomeTile,
  ROITile
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
const DashboardApp = () => {
  // Define state, local state vars, and ref (ref to refer directly to DOM)
  const { state } = useLocation();
  const [printing, setPrinting] = useState(false);
  const componentRef = createRef();

  // Function to print entire page as png
  const handlePrint = () => {
    htmlToImage.toPng(document.getElementById('dashboard')).then(async (data) => {
      await download(data, `Financial Projections, ${state.address}.png`);
      setPrinting(false); // Hide OH logo afer finished printing
    });
  };

  return (
    <Page id="dashboard" title="Dashboard | Opoku Homes" ref={componentRef}>
      <Container maxWidth="xl" sx={{ backgroundColor: 'white' }}>
        <Box sx={{ pb: printing ? -5 : 5 }}>
          <Grid container justify="space-between">
            {printing ? (
              <Logo
                sx={{
                  width: '15%',
                  m: 0,
                  visibility: printing ? 'visible' : 'hidden'
                }}
              />
            ) : (
              <Typography
                sx={{ visibility: printing ? 'hidden' : 'visible' }}
                variant="h4"
              >{`Welcome back, ${account.displayName}`}</Typography>
            )}

            {/* Horizontal spacer */}
            <div style={{ flexGrow: 1 }} />
            <Typography
              sx={{ marginRight: '1rem', paddingTop: printing ? '2%' : 0 }}
              variant="h6"
              align="right"
            >
              {state.address}
              <br />
              <p style={{ fontWeight: 'lighter', color: '#637381', fontSize: '15px' }}>
                {state.city}, {state.state}
              </p>
            </Typography>
          </Grid>

          <Grid container justify="space-between">
            <Button
              style={{ textTransform: 'none', visibility: printing ? 'hidden' : 'visible' }} // TextTransform makes button text non-uppercase
              onClick={() => {
                setPrinting(true); // Show OH logo for printing
                handlePrint();
              }}
              startIcon={<Icon icon="fluent:print-20-filled" />}
            >
              Print report
            </Button>
          </Grid>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <CashFlowTile state={state} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <IncomeTile state={state} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ExpensesTile state={state} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ROITile state={state} />
          </Grid>

          {/* Insert Property/Loan, FinancialMeasures, and MonthlyExpense Info cards when page printed */}
          <PropertyInfo state={state} />
          <FinancialMeasures state={state} />

          {/* Add spacing to force graph onto new page */}
          {/* <div style={{ marginBottom: '42.5rem' }} /> */}

          <Grid item xs={12} md={8} lg={8}>
            <CashflowPaymentsTile state={state} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <ExpenseBreakdownTile state={state} />
          </Grid>

          {/* Add spacing to force chart onto new page */}
          {/* <div style={{ marginBottom: '62.5rem' }} /> */}

          {/* Insert Cashflow/P&I payments table when page printed */}
          <Grid item xs={12} md={12} lg={12}>
            <CashflowPaymentsTable state={state} />
          </Grid>

          {/* <Grid item xs={12} md={12} lg={12}>
            <RentalCompsTile state={state} />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardApp;
