// material
import { Box, Card, CardHeader } from '@mui/material';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

// Function to compute average of given array
const avgPercIncrease = (arr) => {
  // Function get % increases in a given array
  const increases = arr
    .map((curr, i) => {
      if (!i) return;
      const prev = arr[i - 1];
      // eslint-disable-next-line consistent-return
      return ((curr - prev) / prev) * 100;
    })
    .filter(Boolean);
  // Return the average percentage increase to two decimal places with (+/-) sign
  const ret = parseFloat(increases.reduce((a, b) => a + b) / arr.length).toFixed(2);
  return `${ret < 0 ? '-' : '+'}${ret}`;
};

const CashflowPaymentsTile = ({ state }) => {
  // Extract necessary prop, then define chart data
  const { cashflowPIGraph } = state;
  const CHART_DATA = [
    {
      name: 'Yearly cashflow',
      type: 'line',
      data: cashflowPIGraph.cashflow
    },
    {
      name: 'Principle remaining',
      type: 'line',
      data: cashflowPIGraph.principlePaid
        .map((e, i, arr) => {
          if (i === arr.length - 1) return 0.01;
          // Get principle remaining from lastEle - currEle
          return arr.slice(-1) - e;
        })
        .filter(Boolean) // Trick to filter out non-integer values (e.g., null)
    },
    {
      name: 'Interest remaining',
      type: 'line',
      data: cashflowPIGraph.interestPaid
        .map((e, i, arr) => {
          if (i === arr.length - 1) return 0.01;
          // Get interest remaining from lastEle - currEle
          return arr.slice(-1) - e;
        })
        .filter(Boolean) // Trick to filter out non-integer values (e.g., null)
    }
  ];

  // Define current year and chart config
  const currYear = new Date().getFullYear();

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [3, 2, 2, 2] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'solid', 'solid'] },
    colors: ['#66DA26', '#2E93fA', '#546E7A'],
    labels: cashflowPIGraph.years,
    xaxis: {
      // type: 'datetime'
      labels: {
        formatter: (x) => {
          // Use regex to prepend x-axis val with "Year"
          if (typeof x !== 'undefined') return `Year ${x}`;
          return x;
        }
      }
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: (y) => {
          // Use regex to add commas to number in y axis
          if (typeof y !== 'undefined')
            return `$${Math.round(y)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return y;
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          // Use regex to add commas to number in tool tip
          if (typeof y !== 'undefined')
            return `$${Math.round(y)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader
        title="Cashflow and P&I payments"
        subheader={`${avgPercIncrease(cashflowPIGraph.cashflow)}% cashflow per year (on average)`}
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
};

export default CashflowPaymentsTile;
