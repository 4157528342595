import { LoadingButton } from '@mui/lab';
// material
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slider,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculate } from '../../calculator/calculate';
import { InputSchema } from './InputSchema';

// ----------------------------------------------------------------------

export default function InputForm() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    address: '',
    city: '',
    state: '',
    income: '',
    increase: '',
    construction: true,
    price: '',
    closing: '',
    collateral: '',
    repairs: '',
    arv: '',
    down: 20,
    term: '',
    ir: '',
    taxes: '',
    insurance: '',
    maintenance: '',
    vacancy: '',
    capex: '',
    mgmt: '',
    elec: '',
    water: ''
  });
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const [percDown, setPercDown] = useState(20);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Property information',
    'Purchase information',
    'Loan details',
    'Income',
    'Expenses'
  ];
  const marks = [
    {
      value: 10,
      label: '10'
    },
    {
      value: 30,
      label: '30'
    },
    {
      value: 50,
      label: '50'
    }
  ];

  // Function to restore form data on component render
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) setData(items);
  }, []);

  // Configuring formik and onSubmit action (use `data` if not null, else obj w/ empty vals)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: data.address,
      city: data.city,
      state: data.state,
      income: data.income,
      increase: data.increase,
      construction: data.construction,
      price: data.price,
      closing: data.closing,
      collateral: data.collateral,
      repairs: data.repairs,
      arv: data.arv,
      down: data.down,
      term: data.term,
      ir: data.ir,
      taxes: data.taxes,
      insurance: data.insurance,
      maintenance: data.maintenance,
      vacancy: data.vacancy,
      capex: data.capex,
      mgmt: data.mgmt,
      elec: data.elec,
      water: data.water
    },
    validationSchema: InputSchema,
    onSubmit: () => {
      // Calculate numbers
      const {
        monthlyExpenses,
        cocROI,
        noi,
        goi,
        capRate,
        fiftyPerc,
        monthlyCashFlow,
        cashflowPIGraph,
        monthlyBreakdown,
        oer,
        projectedARV,
        ...input
      } = calculate(
        getFieldProps('income').value,
        getFieldProps('increase').value,
        getFieldProps('construction').value,
        getFieldProps('price').value,
        getFieldProps('closing').value,
        getFieldProps('collateral').value,
        getFieldProps('repairs').value,
        getFieldProps('arv').value,
        getFieldProps('down').value,
        getFieldProps('term').value,
        getFieldProps('ir').value,
        getFieldProps('taxes').value,
        getFieldProps('insurance').value,
        getFieldProps('maintenance').value,
        getFieldProps('vacancy').value,
        getFieldProps('capex').value,
        getFieldProps('mgmt').value,
        getFieldProps('elec').value,
        getFieldProps('water').value
      );

      // Store values in local storage
      localStorage.setItem(
        'items',
        JSON.stringify({
          address: getFieldProps('address').value,
          city: getFieldProps('city').value,
          state: getFieldProps('state').value,
          income: getFieldProps('income').value,
          increase: getFieldProps('increase').value,
          consruction: getFieldProps('consruction').value,
          price: getFieldProps('price').value,
          closing: getFieldProps('closing').value,
          collateral: getFieldProps('collateral').value,
          repairs: getFieldProps('repairs').value,
          arv: getFieldProps('arv').value,
          down: getFieldProps('down').value,
          term: getFieldProps('term').value,
          ir: getFieldProps('ir').value,
          taxes: getFieldProps('taxes').value,
          insurance: getFieldProps('insurance').value,
          maintenance: getFieldProps('maintenance').value,
          vacancy: getFieldProps('vacancy').value,
          capex: getFieldProps('capex').value,
          mgmt: getFieldProps('mgmt').value,
          elec: getFieldProps('elec').value,
          water: getFieldProps('water').value
        })
      );

      // Navigate to dashboard
      navigate('/dashboard/app', {
        state: {
          address: getFieldProps('address').value,
          city: getFieldProps('city').value,
          state: getFieldProps('state').value,
          monthlyExpenses,
          cocROI,
          noi,
          goi,
          capRate,
          fiftyPerc,
          monthlyCashFlow,
          cashflowPIGraph,
          monthlyBreakdown,
          oer,
          projectedARV,
          input
        }
      });
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  // Functions to handle stepper Next and Back buttons
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Function to show input fields step by step
  const displayFormContent = (index) => {
    // Property info
    if (index === 0)
      return (
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Property address"
            // defaultValue={data.address}
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />
          <TextField
            fullWidth
            label="City"
            // defaultValue={data.city}
            {...getFieldProps('city')}
            error={Boolean(touched.city && errors.city)}
            helperText={touched.city && errors.city}
          />
          <TextField
            fullWidth
            label="State"
            // defaultValue={data.state}
            {...getFieldProps('state')}
            error={Boolean(touched.state && errors.state)}
            helperText={touched.state && errors.state}
          />
          <br />
        </Stack>
      );
    // Purchase info
    if (index === 1)
      return (
        <Stack spacing={3}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Is this a ground-up construction project?</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={switchEnabled}
                    onChange={(_, val) => {
                      setSwitchEnabled(val);
                      setFieldValue('construction', val);
                    }}
                  />
                }
                label={switchEnabled ? 'Yes' : 'No'}
              />
            </FormGroup>
          </FormControl>
          <TextField
            fullWidth
            type="number"
            // defaultValue={data.price}
            label={switchEnabled ? 'Construction cost' : 'Purchase price'}
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
          />
          <TextField
            fullWidth
            type="number"
            label="Closing costs"
            {...getFieldProps('closing')}
            error={Boolean(touched.closing && errors.closing)}
            helperText={touched.closing && errors.closing}
          />
          {/* Conditionally render text boxes depending on new vs existing construction project */}
          {switchEnabled ? (
            <TextField
              fullWidth
              type="number"
              label="Value of land collateral"
              {...getFieldProps('collateral')}
              error={Boolean(touched.collateral && errors.collateral)}
              helperText={touched.collateral && errors.collateral}
            />
          ) : (
            <TextField
              fullWidth
              type="number"
              label="Estimated repair costs"
              {...getFieldProps('repairs')}
              error={Boolean(touched.repairs && errors.repairs)}
              helperText={touched.repairs && errors.repairs}
            />
          )}
          <TextField
            fullWidth
            type="number"
            label="Estimated ARV"
            // defaultValue={data.arv}
            {...getFieldProps('arv')}
            error={Boolean(touched.arv && errors.arv)}
            helperText={touched.arv && errors.arv}
          />
          <br />
        </Stack>
      );

    // Loan details
    if (index === 2)
      return (
        <Stack spacing={3}>
          <Stack spacing={3} direction="row" alignItems="center">
            <Typography variant="body2" component="div">
              Down payment (%)
            </Typography>
            <Slider
              key="Slider"
              aria-label="Down payment (%)"
              // defaultValue={percDown}
              value={percDown}
              onChange={(_, val) => {
                setPercDown(val);
                setFieldValue('down', val);
              }}
              valueLabelDisplay="on"
              step={1}
              marks={marks}
              min={0}
              max={100}
            />
          </Stack>
          <TextField
            fullWidth
            type="number"
            label="Loan term"
            // defaultValue={data.term}
            {...getFieldProps('term')}
            error={Boolean(touched.term && errors.term)}
            helperText={touched.term && errors.term}
          />
          <TextField
            fullWidth
            type="number"
            label="Interest rate (%)"
            // defaultValue={data.ir}
            {...getFieldProps('ir')}
            error={Boolean(touched.ir && errors.ir)}
            helperText={touched.ir && errors.ir}
          />

          <br />
        </Stack>
      );

    // Income
    if (index === 3)
      return (
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="number"
            label="Monthly income"
            // defaultValue={data.income}
            {...getFieldProps('income')}
            error={Boolean(touched.income && errors.income)}
            helperText={touched.income && errors.income}
          />
          <TextField
            fullWidth
            type="number"
            label="Yearly rent increase (%)"
            // defaultValue={data.increase}
            {...getFieldProps('increase')}
            error={Boolean(touched.increase && errors.increase)}
            helperText={touched.increase && errors.increase}
          />
          <br />
        </Stack>
      );

    // Expenses
    if (index === 4)
      return (
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="number"
            label="Yearly taxes"
            // defaultValue={data.taxes}
            {...getFieldProps('taxes')}
            error={Boolean(touched.taxes && errors.taxes)}
            helperText={touched.taxes && errors.taxes}
          />
          <TextField
            fullWidth
            type="number"
            label="Monthly insurance"
            // defaultValue={data.insurance}
            {...getFieldProps('insurance')}
            error={Boolean(touched.insurance && errors.insurance)}
            helperText={touched.insurance && errors.insurance}
          />
          <TextField
            fullWidth
            type="number"
            label="Maintenance (%)"
            // defaultValue={data.maintenance}
            {...getFieldProps('maintenance')}
            error={Boolean(touched.maintenance && errors.maintenance)}
            helperText={touched.maintenance && errors.maintenance}
          />
          <TextField
            fullWidth
            type="number"
            label="Vacancy (%)"
            // defaultValue={data.vacancy}
            {...getFieldProps('vacancy')}
            error={Boolean(touched.vacancy && errors.vacancy)}
            helperText={touched.vacancy && errors.vacancy}
          />
          <TextField
            fullWidth
            type="number"
            label="Capital Expenditures (%)"
            // defaultValue={data.capex}
            {...getFieldProps('capex')}
            error={Boolean(touched.capex && errors.capex)}
            helperText={touched.capex && errors.capex}
          />
          <TextField
            fullWidth
            type="number"
            label="Property management (%)"
            // defaultValue={data.mgmt}
            {...getFieldProps('mgmt')}
            error={Boolean(touched.mgmt && errors.mgmt)}
            helperText={touched.mgmt && errors.mgmt}
          />
          <TextField
            fullWidth
            type="number"
            label="Electricity"
            // defaultValue={data.elec}
            {...getFieldProps('elec')}
            error={Boolean(touched.elec && errors.elec)}
            helperText={touched.elec && errors.elec}
          />
          <TextField
            fullWidth
            type="number"
            label="Water"
            // defaultValue={data.water}
            {...getFieldProps('water')}
            error={Boolean(touched.water && errors.water)}
            helperText={touched.water && errors.water}
          />
        </Stack>
      );

    // Default return
    return 'Nothing to show here...';
  };

  // Function to verify whether a step was actually completed or not (i.e., all fields filled in)
  const checkStepCompleted = (index) => {
    // Return true if some fields left blank
    if (index === 0) return getFieldProps('address').value === '';
    if (index === 1)
      return [getFieldProps('price').value, getFieldProps('closing').value].includes('');
    if (index === 2)
      return [
        getFieldProps('down').value,
        getFieldProps('term').value,
        getFieldProps('ir').value,
        getFieldProps('arv').value
      ].includes('');

    if (index === 3)
      return [getFieldProps('income').value, getFieldProps('increase').value].includes('');
    if (index === 4)
      return [
        getFieldProps('taxes').value,
        getFieldProps('insurance').value,
        getFieldProps('maintenance').value,
        getFieldProps('vacancy').value,
        getFieldProps('capex').value,
        getFieldProps('mgmt').value,
        getFieldProps('elec').value,
        getFieldProps('water').value
      ].includes('');
    return true;
  };

  // Rendered content
  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step}
              </StepLabel>
              <StepContent>
                {displayFormContent(index)}
                <Box sx={{ mb: 2 }}>
                  <div>
                    {index !== steps.length - 1 ? (
                      <Button
                        disabled={checkStepCompleted(index)}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Next
                      </Button>
                    ) : null}

                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <LoadingButton
          style={{ marginTop: '1.5rem' }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={checkStepCompleted(steps.length - 1)}
          loadingIndicator="Calculating"
        >
          Calculate
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
