/* eslint-disable import/no-unresolved */
// material
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

// Function to change table font size
const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: 4
  }
}));

// Function to format dollar amounts with commas
const format = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Stateless functional component
const CashflowPaymentsTable = ({ state }) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{ fontSize: '6px', padding: '1.5rem', marginTop: '5rem' }}
    >
      <Typography inline variant="h6" sx={{ paddingLeft: '1rem' }}>
        Financial projections over loan term
      </Typography>
      <Typography
        inline
        variant="body2"
        color="#637381"
        sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
      >
        Assumes +2% property value, +2% expenses, and +{state.input.increase}% rental income per
        year
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Financial measure</TableCell>
              {state.cashflowPIGraph.years.map((row, i) => (
                <TableCell key={i}>Year {row}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Yearly income row */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Yearly income
              </TableCell>
              {state.cashflowPIGraph.income.map((row, i) => (
                <TableCell key={i} component="th" scope="row">
                  ${format(row.toFixed(2))}
                </TableCell>
              ))}
            </TableRow>
            {/* Yearly expenses row */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Yearly expenses
              </TableCell>
              {state.cashflowPIGraph.expenses.map((row, i) => (
                <TableCell key={i} component="th" scope="row">
                  ${format(row.toFixed(2))}
                </TableCell>
              ))}
            </TableRow>
            {/* Yearly cash flow row */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Yearly cash flow
              </TableCell>
              {state.cashflowPIGraph.cashflow.map((row, i) => (
                <TableCell key={i} component="th" scope="row">
                  ${format(row.toFixed(2))}
                </TableCell>
              ))}
            </TableRow>
            {/* Principle remaining row */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Principle remaining
              </TableCell>
              {state.cashflowPIGraph.principlePaid.map((row, i, arr) => (
                <TableCell key={i} component="th" scope="row">
                  {/* Get principle remaining from lastEle - currEle */}$
                  {format((arr.slice(-1) - row).toFixed(2))}
                </TableCell>
              ))}
            </TableRow>
            {/* Interest remaining row */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Interest remaining
              </TableCell>
              {state.cashflowPIGraph.interestPaid.map((row, i, arr) => (
                <TableCell key={i} component="th" scope="row">
                  {/* Get interest remaining from lastEle - currEle */}$
                  {format((arr.slice(-1) - row).toFixed(2))}
                </TableCell>
              ))}
            </TableRow>
            {/* Property value */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Property value
              </TableCell>
              {state.cashflowPIGraph.propVal.map((row, i) => (
                <TableCell key={i} component="th" scope="row">
                  ${format(row.toFixed(2))}
                </TableCell>
              ))}
            </TableRow>
            {/* Annualized IRR */}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Annualized IRR
              </TableCell>
              {state.cashflowPIGraph.irr.map((row, i) => (
                <TableCell key={i} component="th" scope="row">
                  {format(row.toFixed(2))}%
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default CashflowPaymentsTable;
