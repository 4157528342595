/* eslint-disable no-plusplus */
import { mortgage } from './mortgage';

// Function to perform rental calculations
export const calculate = (
  income,
  increase,
  construction,
  price,
  closing,
  collateral = 0, // Default values in case null
  repairs = 0,
  arv,
  percDown,
  term,
  ir,
  taxes,
  insurance,
  maintenance,
  vacancy,
  capex,
  mgmt,
  elec,
  water
) => {
  // Redefine price if collateral included (if construction project)
  if (construction) price += collateral;

  const { monthlyMortgage, paymentSchedule } = mortgage(
    ir / 100,
    price * (1 - percDown / 100),
    term * 12
  );

  const monthlyExpenses =
    monthlyMortgage +
    taxes / 12 +
    insurance +
    (income * (maintenance + vacancy + capex + mgmt)) / 100 +
    elec +
    water;
  const monthlyBreakdown = {
    Mortgage: monthlyMortgage,
    Taxes: taxes / 12,
    Insurance: insurance,
    Maintenance: (income * maintenance) / 100,
    Vacancy: (income * vacancy) / 100,
    CapEx: (income * capex) / 100,
    Management: (income * mgmt) / 100,
    Electricity: elec,
    Water: water
  };
  const monthlyCashFlow = income - monthlyExpenses;
  const initInvestment = (price * percDown) / 100 + closing;
  const cocROI = ((12 * monthlyCashFlow) / initInvestment) * 100;
  const goi = 12 * income; // GOI is income less vacancy...assuming 10% occupancy for easy numbers for now
  const operatingExpenses =
    12 *
    (monthlyBreakdown.Electricity +
      monthlyBreakdown.Water +
      monthlyBreakdown.Taxes +
      monthlyBreakdown.Insurance +
      monthlyBreakdown.Vacancy +
      monthlyBreakdown.Maintenance +
      monthlyBreakdown.Management);
  const noi = goi - operatingExpenses;
  // const noi = 12 * (income - monthlyExpenses + monthlyMortgage + monthlyBreakdown.CapEx); // NOI does't account for loan payments, depreciation, or capex
  const projectCost = construction ? price - collateral : price;
  const capRate = (noi / projectCost) * 100;
  const fiftyPerc = 2 * (monthlyExpenses - monthlyMortgage);
  const oer = (monthlyExpenses - monthlyMortgage - monthlyBreakdown.CapEx) / income;
  // const projectedARV = noi / (capRate / 100);

  // P & I graph over loan term
  const cashflowPIGraph = {
    years: [],
    income: [],
    expenses: [],
    cashflow: [],
    principlePaid: [],
    interestPaid: [],
    propVal: [],
    irr: []
  };
  // console.log('Year 0:');
  // console.log(`Yearly cashflow:${12 * monthlyCashFlow}`);
  let principlePaid = 0;
  let interestPaid = 0;
  let yearCashflow = 0;
  let yearIncome = 0;
  let yearExpenses = 0;
  const cashflowArr = [-price];
  const YEARS = [1, 2, 3, 4, 5, 10, 15, 20, 30];
  const MONTHS = YEARS.map((x) => x * 12);
  paymentSchedule.every((ele, i) => {
    principlePaid += ele.principal;
    interestPaid += ele.interest;
    // Calculate cashflow yearly
    if (!((i + 1) % 12)) {
      yearIncome = 12 * income * (1 + increase / 100) ** ((i + 1) / 12);
      yearExpenses =
        12 *
        ((monthlyExpenses - monthlyMortgage) * (1 + increase / 100) ** ((i + 1) / 12) +
          monthlyMortgage);
      yearCashflow = yearIncome - yearExpenses;
      cashflowArr.push(yearCashflow);
    }
    if (MONTHS.includes(i + 1)) {
      const year = (i + 1) / 12;
      // console.log(`Year ${year}`);
      // const yearCashflow = 12 * monthlyCashFlow * (1 + increase / 100) ** ((i + 1) / 12);
      // console.log('principlePaid:', principlePaid, 'interestPaid:', interestPaid);
      // console.log('Yearly cashflow:', yearCashflow);
      // Append current year, cashflow, P & I paid, property value, and annualized IRR to obj arrays
      cashflowPIGraph.years.push((i + 1) / 12);
      cashflowPIGraph.income.push(yearIncome);
      cashflowPIGraph.expenses.push(yearExpenses);
      cashflowPIGraph.cashflow.push(yearCashflow);
      cashflowPIGraph.principlePaid.push(principlePaid);
      cashflowPIGraph.interestPaid.push(interestPaid);
      cashflowPIGraph.propVal.push(arv * 1.02 ** year); // Assumes +2% property val per year
      // Push yearly cashflow and include payout from sale in last year
      cashflowPIGraph.irr.push(
        IRRCalc(
          // Pass in array where property sale price appended to the final year's income
          cashflowArr.map((ele, i) =>
            i === cashflowArr.length - 1 ? ele + cashflowPIGraph.propVal.slice(-1).pop() : ele
          )
        )
      );
    }
    // Break out of loop by returning false
    if (i - 1 === term * 12) return false;
    return true;
  });

  // TODO: Include annualized return figures & IRR to determine optimal time to sell
  // Note: Annualized total return = (total profit if sold) / (equity * years owned)
  // Note: total profit if sold = ARV - sales expenses - mortgage balance - equity + total cashflow so far

  return {
    // Calculated values
    monthlyExpenses,
    cocROI,
    noi,
    goi,
    capRate,
    fiftyPerc,
    monthlyCashFlow,
    cashflowPIGraph,
    monthlyBreakdown,
    oer,
    // projectedARV,
    // Original input values
    income,
    increase,
    construction,
    price,
    closing,
    collateral,
    repairs,
    arv,
    percDown,
    term,
    ir,
    taxes,
    insurance,
    maintenance,
    vacancy,
    capex,
    mgmt,
    elec,
    water
  };
};

// Function to calculate annualized IRR
const IRRCalc = (CArray) => {
  let r = 0;
  let min = -1.0;
  let max = 10000.0;
  let guess = 0;
  do {
    guess = (min + max) / 2;
    let NPV = 0;
    for (let j = 0; j < CArray.length; j++) {
      NPV += CArray[j] / (1 + guess) ** j;
    }
    if (NPV > 0) {
      min = guess;
    } else {
      max = guess;
    }
    r++;
  } while (r < 100);
  // console.log('array:', CArray, 'irr guess:', guess * 100);
  return guess * 100;
};
